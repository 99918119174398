import { sendRequest, BASE_URL } from "../../sendRequest";
import { ApiUser } from "./models/ApiUser";

export const setCsrfCookie = () : Promise<void> =>
    sendRequest<void>({
        method: "GET",
        url: `${BASE_URL}/sanctum/csrf-cookie`
    });

export const getAuthenticatedUser = () : Promise<ApiUser> => 
    sendRequest<ApiUser>({
        method: "GET",
        url: `${BASE_URL}/api/user`
    });

export const login = (formData: FormData) : Promise<ApiUser> => 
    sendRequest<ApiUser>({
        method: "POST",
        url: `${BASE_URL}/login`,
        content: formData
    });

export const logout = () : Promise<void> => 
    sendRequest<void>({
        method: "GET",
        url: `${BASE_URL}/logout`
    });