import { sendRequest } from "../../sendRequest";
const BASE_URL = '/api';

export const uploadFile = (file: File) : Promise<void> => {
    var data = new FormData();
    data.append("file", file);

    return sendRequest<void>({
        method: "POST",
        url: `${BASE_URL}/formFile/upload`,
        content: data,
        headers: { "Content-Type": "multipart/form-data" }
    });
}

export const getFileUrl = (fileName: string) : string => {
    return `${BASE_URL}/formFile/get/${fileName}`;
}