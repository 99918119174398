import { sendRequest } from "../../sendRequest";
import { Form } from "../types";

const BASE_URL = '/api';

export const getFormTemplate = (templateId: number): Promise<Form> =>
	sendRequest<Form>({
		method: "GET",
		url: `${BASE_URL}/formTemplate/${templateId}`
	});

export const getForm = (formId: number): Promise<Form> =>
	sendRequest<Form>({
		method: "GET",
		url: `${BASE_URL}/form/${formId}`
	});

export const deleteForm = (formId: number) : Promise<void> =>
    sendRequest<void>({
        method: "DELETE",
        url: `${BASE_URL}/form/${formId}`
    })

export const getForms = (): Promise<Form[]> => 
    sendRequest<Form[]>({
        method: "GET",
        url: `${BASE_URL}/forms`
    })

export const updateForm = (form: Form) : Promise<void> => 
    sendRequest<void>({
        method: "PUT",
        url: `${BASE_URL}/form`,
        content: JSON.stringify(form)
    });

export const makeFormReady = (formId: number) : Promise<void> => 
    sendRequest<void>({
        method: "PUT",
        url: `${BASE_URL}/form/ready`,
        content: `{"id": ${formId}}`
    });

export const createForm = (form: Form) : Promise<Form> => 
    sendRequest<Form>({
        method: "POST",
        url: `${BASE_URL}/form`,
        content: JSON.stringify(form)
    });

