import React, {useEffect, useState} from 'react';
import FormItemWrapper from '../..';
import {FormItemProps} from '../../types';
import { Button } from '@material-ui/core';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import {AnswerGroup, FormItem} from '../../../../../types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';

export default function RepeatingChildren({item, key} : FormItemProps){
    const [answerGroups, setAnswerGroups] = useState<AnswerGroup[]>(item.answerGroups);
    const [elementId, setElementId] = useState(1);

    function addNew(){
        let newItems = JSON.parse(JSON.stringify(item.children));
        let newGroup : AnswerGroup = {id: null, elementId: elementId, children: newItems};
        setAnswerGroups([...answerGroups, newGroup]);
        setElementId(elementId + 1);
        item.answerGroups.push(newGroup);
    }

    const deleteChild = (index: number) => {
        let newArray = [...answerGroups];
        newArray.splice(index, 1);
        setAnswerGroups(newArray);
        item.answerGroups.splice(index, 1);
    };

    return(
        <Accordion TransitionProps={{ unmountOnExit: true }} key={key} defaultExpanded={true}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
          <Typography>{item.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Stack spacing={1}>
                {
                    answerGroups.map(function(answerGroup, index){
                        return(
                            <div key={answerGroup.id !== null ? answerGroup.id : `el${answerGroup.elementId}`}>
                                {
                                    answerGroup.children.map(function(item, ii){
                                        return (<FormItemWrapper item={item} key={ii} />)
                                    })
                                }
                                <br />
                                <Button size="small" variant="outlined" onClick={() => deleteChild(index)}>
                                    Verwijderen
                                </Button>
                                <hr />
                            </div>
                        )                    
                    })
                }
                <Fab variant="extended" size="small" color="primary" aria-label="add" onClick={addNew}>
                    <AddIcon /> Nieuwe toevoegen
                </Fab>
            </Stack>
        </AccordionDetails>
      </Accordion>       
    )
}