import React, { useEffect, useState, Suspense } from 'react';
import Loader from './Loader';
import './App.css';
import { getAuthenticatedUser, Login, User } from './Auth';
import { Button, Snackbar } from '@mui/material';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from './Layout';

function App() {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);
  const [authenticated, setAuthenticated] = useState(User.isLoggedIn());

  useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
    
    if(User.isLoggedIn()){
      // If user logged in --> Check if session is still valid and refresh user data
      getAuthenticatedUser()
        .then((data) => {
          User.authenticated(data);
        })
        .catch(() => {
          User.unauthenticated();
          setAuthenticated(false);
        });
    }
  }, []);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload();
  };

  const handleAuthenticated = (isAuthenticated: boolean) => {
    setAuthenticated(isAuthenticated);
  }

  return (
    <React.Fragment>
      <Suspense fallback={<Loader />}>
        <Snackbar
          open={showReload}
          message="A new version is available!"
          onClick={reloadPage}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={reloadPage}
            >
              Reload
            </Button>
          }
        />
      </Suspense>
      {
        authenticated ?
          <Layout authenticatedEvent={handleAuthenticated} />
          :
          <>
            <Login authenticatedEvent={handleAuthenticated} pAuthenticated={authenticated} /> 
          </>
      }
    </React.Fragment>
  );
}

export default App;
