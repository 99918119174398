import { sendRequest } from "../../sendRequest";
import { FormGroup } from "../types";

const BASE_URL = '/api';

export const getFormGroup = (id: number): Promise<FormGroup> =>
    sendRequest<FormGroup>({
        method: "GET",
        url: `${BASE_URL}/formGroup/${id}`
    })

export const getFormGroups = (): Promise<FormGroup[]> => 
    sendRequest<FormGroup[]>({
        method: "GET",
        url: `${BASE_URL}/formGroups`
    })

export const createFormGroup = (formGroup: FormGroup) : Promise<FormGroup> => 
    sendRequest<FormGroup>({
        method: "POST",
        url: `${BASE_URL}/formGroup`,
        content: JSON.stringify(formGroup),
    });

export const updateFormGroup = (formGroup: FormGroup) : Promise<FormGroup> => 
    sendRequest<FormGroup>({
        method: "PUT",
        url: `${BASE_URL}/formGroup`,
        content: JSON.stringify(formGroup)
    });

export const deleteFormGroup = (formGroupId: number) : Promise<void> =>
    sendRequest<void>({
        method: "DELETE",
        url: `${BASE_URL}/formGroup/${formGroupId}`
    })