import React, { useState } from 'react';
import { FormItemProps } from '../../types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormGroup } from '@material-ui/core';
import { FormLabel } from '@mui/material';

export default function MultiCheckboxes({item} : FormItemProps){
    const [answers, setAnswers] = useState(item.answers === null ? [] : item.answers);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>){
        if(event.target.checked && (answers.find(a => a === event.target.value) === undefined)){
           let newAnswers = [...answers, event.target.value];
           setAnswers(newAnswers);
           item.answers = newAnswers;
        }
        else{
            let index = answers.indexOf(event.target.value);

            if(index !== -1){
                let newAnswers = [...answers];
                newAnswers.splice(index, 1);
                setAnswers(newAnswers);
                item.answers = newAnswers;
            }
        }
    }

    return(
        <FormGroup>
            <FormLabel component="legend">
                {item.title}
            </FormLabel>
            {
                item.answerOptions.map(function(option, index){
                    let checked : boolean = answers.find(a => a === option.id.toString()) !== undefined;

                    return(
                        <FormControlLabel
                            key={index} 
                            control={
                                <Checkbox 
                                    value={option.id.toString()}
                                    onChange={handleChange} 
                                    checked={checked}
                                />} 
                            label={option.title} 
                        />
                    );
                })
            }
        </FormGroup>
    )
}