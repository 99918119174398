import React, {useState, useEffect} from 'react';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { FormItemProps } from '../../types';
import styles from './index.module.css';
import SignaturePad from 'react-signature-pad-wrapper'; //https://www.npmjs.com/package/react-signature-pad-wrapper
import { getFileUrl, uploadFile } from '../../../../../api/FormFileService';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
    ) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Draw({item}: FormItemProps) {
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState("");
    let signaturePad : SignaturePad | null = null;

    useEffect(() => {
        if(item.answers !== undefined && item.answers !== null && item.answers.length > 0)
            setImage(getFileUrl(item.answers[0]));
    
    }, [item])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        handleSave();
        setOpen(false);
    };

    const handleClear = () => {
        if(signaturePad !== null){
            signaturePad.clear(); 
        }
    }

    const handleSave = () => {
        function dataURLtoFile(dataurl : any) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
    
            var blob = new Blob([u8arr], {type:mime});
            return new File([blob], "name");
        }

        if(signaturePad){
            if (signaturePad.isEmpty()) {
                item.answers = [];
            } else {
                let dataUrl = signaturePad.toDataURL();
                var file = dataURLtoFile(dataUrl);
                
                uploadFile(file)
                    .then((data : any) => {
                        let newAnswers = [data.filename];
                        item.answers = newAnswers;

                        setImage(dataUrl);
                    })
                    .catch(() => alert("ERROR!!")); // TODO toast
            }
        }
    }

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">{item.title}</FormLabel>
            {
                image !== "" &&
                    <img src={image} className={styles.imagePreview} />
            }
            <Button variant="outlined" onClick={handleClickOpen}>
                Nieuwe tekening
            </Button>

            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        >
                        <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Tekening
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                        Opslaan
                        </Button>
                    </Toolbar>
                </AppBar>
                <SignaturePad 
                    ref={(ref) => (signaturePad = ref)} 
                    height={400}
                />
                <Button variant="outlined" onClick={handleClear}>
                    Opnieuw
                </Button>
            </Dialog>
        </FormControl>
    );
}