export interface FormProps{
    form: Form,
    onClose: () => void
}

export type FormTemplate = {
    id: number,
    name: string
}

export type Form = {
    id?: number,
    title?: string,
    templateName?: string,
    templateId?: number,
    items: FormItem[],
    createdAt?: string,
    status?: FormStatus,
    formGroupId?: number,
}

export type FormItem = {
    id?: number,
    type: FormItemType,
    title: string,
    answerOptions: AnswerOption[],
    answerGroups: AnswerGroup[],
    answers: string[] | null,
    children: FormItem[]
}

export type AnswerOption = {
    id: number,
    title: string
}

export type AnswerGroup = {
    id: number | null,
    elementId: number | null, // Fix with create/delete inside react TODO
    children: FormItem[]
}

export enum FormItemType {
    Category = 'Category',
    RepeatingChildren = 'RepeatingChildren',
    SingleCheckbox = 'SingleCheckbox',
    MultiCheckboxes = 'MultiCheckboxes',
    MultiRadio = 'MultiRadio',
    TextArea = 'TextArea',
    Picture = 'Picture',
    Draw = 'Draw',
    TextField = 'TextField'
}

export enum FormStatus{
    created = "created",
    filled = "filled",
    ready = "ready",
    downloaded = "downloaded"
}