import React, {useEffect, useReducer, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import {Navigate} from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Loader from '../../Loader';
import { getFormGroupDetailFields } from '../api/FormGroupDetailFieldService';
import { FormGroupDetail, FormGroupDetailField } from '../types';
import { Alert } from '@mui/material';
import { createFormGroup } from '../api/FormGroupService';

export default function CreateForm(){
    // const [name, setName] = useState("");
    // const [templates, setTemplates] = useState<FormTemplate[]>([]);d
    // const [templateId, setTemplateId] = useState<number | undefined>(undefined);
    // 

    const [formGroupDetails, setFormGroupDetails] = useState<FormGroupDetailField[]>([]);
    const [createdId, setCreatedId] = useState<number | undefined>(undefined);
    const [redirectBack, setRedirectBack] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [values, setValues] = useState<Map<number, string>>(new Map<number, string>());
    const [hasErrors, setHasErrors] = useState(false);

    useEffect(() => {
        getFormGroupDetailFields()
            .then((data) => {
                setFormGroupDetails(data);
                setIsLoading(false);
            });
    }, [])

    function onChange(event: React.ChangeEvent<HTMLInputElement>){
        const id : number = +event.target.name;
        const newValues = values;
        newValues.set(id, event.target.value);
        setValues(newValues);
    }

    function validate(){
        if(values.size !== formGroupDetails.length){
            return false;
        }

        values.forEach((value) => {
            if(value === ''){
                return false;
            }
        });

        return true;
    }

    function onSubmit(){
        const isValid = validate();
        setHasErrors(!isValid);

        if (isValid){
            const details : FormGroupDetail[] = [];

            values.forEach((value, id) => {
                details.push({field_id: id, content: value});
            })

            createFormGroup({
                details: details,
                forms: [],
            })
                .then((data) => {
                    setCreatedId(data.id);
                });
        }
    }

    return(
        isLoading ?
            <Loader />
        :
        <>
            {
                redirectBack || createdId !== undefined ?
                    <Navigate to={"/"} />
                : 
                    <>
                        <AppBar position="fixed">
                            <Toolbar variant="dense">
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2 }}
                                >   
                                    <CloseIcon onClick={() => setRedirectBack(true)} />           
                                </IconButton>
                                <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }}>
                                    Nieuw dossier
                                </Typography>
                                </Toolbar>    
                        </AppBar>
                        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                            Nieuw dossier
                        </Typography>

                        {
                            hasErrors && <Alert severity="error">Vul alle velden in om een dossier aan te maken.</Alert>
                        }
                        
                        {
                            formGroupDetails.map(element => {
                                return(
                                    <div key={element.id}>
                                        <TextField id="filled-basic" name={element.id.toString()} required fullWidth label={element.title} onChange={onChange} value={values.has(element.id) ? values.get(element.id) : values.get(element.id)} />
                                        <br />
                                        <br />
                                    </div>
                                )
                            })
                        }
                        <Button variant="contained" onClick={onSubmit}>Dossier aanmaken</Button>
                    </>
            }
        </>
    )
}