import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import {Link, Navigate, useNavigate, useParams} from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { createForm } from '../api/FormService';
import { getFormTemplates } from '../api/FormTemplateService';
import { FormTemplate } from '../types';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Loader from '../../Loader';
import { FormGroup } from '../../FormGroup/types';
import { getFormGroup } from '../../FormGroup/api/FormGroupService';

export default function CreateForm(){
    const { formGroupId } = useParams();
    const [formGroup, setFormGroup] = useState<FormGroup | undefined>();
    const [templates, setTemplates] = useState<FormTemplate[]>([]);
    const [templateId, setTemplateId] = useState<number | undefined>(undefined);
    const [createdId, setCreatedId] = useState<number | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if(formGroupId) {
            getFormGroup(+formGroupId)
                .then((dataGroup) => {
                    setFormGroup(dataGroup);

                    getFormTemplates()
                    .then((data) => {
                        setTemplates(data);
                        setIsLoading(false);
                    });
                })
        }
    }, [])

    function onSubmit(){
        createForm({
            items: [],
            templateId: templateId,
            formGroupId: formGroup?.id
        })
            .then((data) => {
                setCreatedId(data.id);
            });
    }

    const handleChange = (e : SelectChangeEvent<number>) => {
        var id : number = +e.target.value;
        setTemplateId(id);
    }

    return(
        isLoading ?
            <Loader />
        :
        <>
            {
                createdId !== undefined ?
                    <Navigate to={"/formGroups/" + formGroupId} />
                :

                    formGroup && 
                    <>
                        <AppBar position="fixed">
                            <Toolbar variant="dense">
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2 }}
                                >   
                                    <CloseIcon onClick={() => navigate("/formGroups/" + formGroupId)} />           
                                </IconButton>
                                <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }}>
                                    Nieuw formulier
                                </Typography>
                                </Toolbar>    
                        </AppBar>
                        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                            {formGroup.title}: Nieuw formulier
                        </Typography>
                        <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Formulier</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={templateId}
                            label="Formulier"
                            onChange={handleChange}
                            required
                            >
                                {
                                    templates.map(template => {
                                        return <MenuItem value={template.id} key={template.id}>
                                            {template.name}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                        </Box>
                        <br /> <br />
                        <Button variant="contained" onClick={onSubmit}>Opslaan</Button>
                    </>
            }
        </>
    )
}