import React, { useEffect, useState } from "react";
import {
    Box, 
    Container,
    TextField,
    CssBaseline,
    Typography,
    Alert
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import User from '../User';
import { login, setCsrfCookie } from "../api/AuthService";
import { Navigate } from "react-router-dom";
import Loader from "../../Loader";

interface LoginProps{
    authenticatedEvent?: (isAuthenticated: boolean) => void,
    pAuthenticated?: boolean
}

function Login({authenticatedEvent, pAuthenticated}: LoginProps) {
    const [errorText, setErrorText] = useState("");
    const [loading, setLoading] = useState(false);
    const [authenticated, setAuthenticated] = useState(pAuthenticated);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setLoading(true);
        const formData = new FormData(event.currentTarget);

        setCsrfCookie()
            .then(() => {
                login(formData).then(user => {
                    User.authenticated(user);

                    setAuthenticated(true);
                    
                    if(authenticatedEvent !== undefined)
                        authenticatedEvent(true);

                })
                .catch(() => setErrorText("Inloggen mislukt"))
                .finally(() => setLoading(false));
            })
            .catch(() => {
                setErrorText("Er is iets misgegaan, probeer het later opnieuw.");
                setLoading(false);
            });
    }

    return (
        <>
        {
            authenticated ? 
                <>
                    <Loader />
                </>
            :
            (
            <Container maxWidth={"xs"}>
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                    <Typography component={"h1"} variant={"h5"}>
                        Forms
                    </Typography>
                    <Box component={"form"} onSubmit={handleSubmit}>
                        {
                            errorText !== '' && <Alert severity="error">{errorText}</Alert>
                        }
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="E-mail"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Wachtwoord"
                            type="password"
                            id="password"
                            autoComplete="wachtwoord"
                        />
                        <LoadingButton
                            loading={loading}
                            loadingIndicator="Inloggen..."
                            fullWidth
                            variant={"outlined"}
                            type={"submit"}
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Inloggen
                        </LoadingButton>
                    </Box>
                </Box>
            </Container>
            )
        }
        </>
    )
}

export default Login;