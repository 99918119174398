import axios from 'axios';

const apiClient = axios.create({
	baseURL: '/',
	withCredentials: true // To work with Laravel Sanctum Authentication
});

export const BASE_URL = '';

export type HttpMethod =
  | "get"
  | "GET"
  | "put"
  | "PUT"
  | "delete"
  | "DELETE"
  | "post"
  | "POST";

export type HttpRequest = {
	method: HttpMethod,
	url: string
	contentType?: string,
	content?: string | FormData,
	headers?: any
}

export async function sendRequest<TResponse>({url, content, contentType, method, headers} : HttpRequest) : Promise<TResponse>{
	const response = await apiClient.request<TResponse>({
		url: url,
		method: method,
		data: content,
		headers: headers
	});

	return response.data;
}
