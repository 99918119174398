import React, { useEffect, useState } from 'react';
import {useParams, Navigate } from 'react-router-dom';
import FormItem from './parts/FormItem';
import { Form as FormModel, FormProps, FormStatus } from '../types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { getForm, makeFormReady, updateForm } from '../api/FormService';
import Loader from '../../Loader';
import Alert from '@mui/material/Alert';

export function FormWrapper(){
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [form, setForm] = useState<FormModel | undefined>();
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        if(id !== undefined){
            let idN : number = +id;

            getForm(idN) 
                .then((data) => {
                    setForm(data);
                    setLoading(false);
                })
        }
        else{
            setLoading(false);
        }
    }, []);

    function onClose(){
        setRedirect(true);
    }

    return(
        <>
        {
            redirect ?
                <Navigate  to={"/formGroups/" + form?.formGroupId} />
            : 
                loading ?
                    <Loader />
                :
                    form !== undefined  ? 
                        <Form form={form} onClose={onClose} />
                    :
                        <div>Niet gevonden!</div>
        }
        </>
    )
}

export default function Form({form, onClose}: FormProps){
    const handleClose = () => {
        if(window.confirm("Let op! Er zijn mogelijk onopgeslagen wijzigingen. Weet je zeker dat je wilt doorgaan?")){
            onClose();
        }
    };

    const handleSave = () => {
        updateForm(form)
            .then(() => { onClose(); })
            .catch(() => alert("Er is een fout opgetreden tijdens het opslaan."))
    };

    const handleMakeReady = () => {
        if(form.id !== undefined){
            makeFormReady(form.id)
            .then(() => { onClose(); })
            .catch(() => alert("Er is een fout opgetreden tijdens het opslaan."))
        }
        else{
            alert("Het is niet mogelijk om dit formulier ready te maken.");
        }
    }

    return(
        <div>
            <AppBar position="fixed">
                <Toolbar variant="dense">
                    <IconButton
                        onClick={handleClose}
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }}>
                        {form.title}
                    </Typography>
                    <Button color="inherit" onClick={handleSave}>Opslaan</Button>
                </Toolbar>    
            </AppBar>
            {
                form.status === FormStatus.filled &&
                    <Button variant="outlined" onClick={handleMakeReady}>Markeer als afgerond</Button>
            }
            {
                form.status === FormStatus.ready &&
                    <Alert severity="warning">
                        <b>Let op! Dit formulier staat gemarkeerd als afgerond.</b><br />
                        Indien je aanpassingen aanbrengt wordt de status gewijzigd naar <b>ingevuld</b>.
                    </Alert>
            }
            {
                form.status === FormStatus.downloaded &&
                    <Alert severity="error">
                        <b>Let op! Dit formulier is al gedownload.</b><br />
                        Indien je iets wijzigt moet het formulier opnieuw gedownload worden. De status wordt aangepast naar ingevuld.
                    </Alert>
            }
            {
                form.items.map(function(item, index){
                    return(
                        <FormItem
                            key={index}
                            item={item}
                        />
                    )
                })
            }
        </div>
    )
}