import React from 'react';
import { FormItemProps } from '../../types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormItem from '../..';
import Stack from '@mui/material/Stack';

export default function Category({key, item}: FormItemProps){
    return(
        <Accordion TransitionProps={{ unmountOnExit: true }} key={key} defaultExpanded={true}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
          <Typography>{item.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Stack spacing={0}>
                {
                    item.children.map(function(child, index){
                        return(
                            <FormItem item={child} key={index} />
                        )
                    })
                }
            </Stack>
        </AccordionDetails>
      </Accordion>
    )
}