import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { logout } from '../api/AuthService';

interface LayoutProps{
    authenticatedEvent?: (isAuthenticated: boolean) => void
}

export default function Logout({authenticatedEvent}: LayoutProps){
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        logout()
            .then(() => {
                setRedirect(true);
                
                if(authenticatedEvent !== undefined)
                    authenticatedEvent(false);
            })
    }, [])

    return(
        <>
            {
                redirect ?
                    <Navigate to="/" />
                :
                    "Uitloggen..."
            }
        </>
    )
}