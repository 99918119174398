import React, { useEffect, useState } from 'react';
import { deleteFormGroup, getFormGroups } from '../api/FormGroupService';
import { FormGroup } from '../types';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Loader from '../../Loader';
import {Link, useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { User } from '../../Auth';
import FolderIcon from '@mui/icons-material/Folder';
import TextField from '@mui/material/TextField';
  
const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));

export default function Overview(){
    const [loading, setLoading] = useState(true);
    const [formGroups, setFormGroups] = useState<FormGroup[]>([]);
    const [filteredFormGroups, setFilteredFormGroups] = useState<FormGroup[]>([]);
    const [searchInput, setSearchInput] = useState<string>();

    useEffect(() => {
        getFormGroups()
            .then((data) => {
                setFormGroups(data);
                setFilteredFormGroups(data);
                setLoading(false);
            })
    }, [])

    const handleSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchFilter = event.target.value;
        setSearchInput(searchFilter);

        const searchFilterLowerCase = searchFilter.toLowerCase();
        const newFilteredFormGroups = formGroups.filter(x => x.title?.toLowerCase().includes(searchFilterLowerCase.trim()));
        setFilteredFormGroups(newFilteredFormGroups);
    }

    const handleDeleteGroup = (id: number) => {
        if(window.confirm(`Weet u zeker dat u dossier ${id} wilt verwijderen?`)){
            deleteFormGroup(id)
                .then(() => {
                    alert("Het formulier is succesvol verwijderd.")

                    // Delete form from display
                    let newArray = [...formGroups];
                    let index = newArray.findIndex(formGroup => formGroup.id === id);
                    newArray.splice(index, 1);
                    setFormGroups(newArray);
                    setFilteredFormGroups(newArray);
                })
                .catch(() => alert("Er is een fout opgetreden tijdens het verwijderen van het dossier."))
        }
    }

    return(
        <>
        {
            loading ?
                <Loader />
            :
                (
            <Grid item xs={12} md={12}>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                    Dossiers
                </Typography>
                <Link to={'/formGroups/create'}>
                    <Button variant="contained">Nieuw dossier</Button>
                </Link>
                <br /><br />
                <TextField
                    id="fullWidth outlined-static"
                    placeholder='Zoeken'
                    fullWidth
                    rows={1}
                    onChange={handleSearchOnChange}
                    value={searchInput}
                />
                <Demo>
                    <List>
                        {
                            filteredFormGroups.length === 0 ?
                                "Er zijn geen dossiers gevonden"
                            :
                                filteredFormGroups.map(function(formGroup){
                                    return( 
                                        <ListItem 
                                            key={formGroup.id}
                                            secondaryAction={ 
                                                (User.role === '1' && 
                                                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteGroup(formGroup.id as number)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                )
                                            }
                                        >
                                            <Link to={"/formGroups/" + formGroup.id} style={{display: "contents"}}>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                    <FolderIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={formGroup.title}
                                                    secondary={`${formGroup.createdAt}`}
                                                />     
                                            </Link>
                                        </ListItem>
                                    )
                                })
                        }                    
                    </List>
                </Demo>
            </Grid>
                )
        }
        </>
    );
}