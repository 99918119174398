import React, {useEffect, useState} from 'react';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {FormItemProps} from '../../types';
import styles from './index.module.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {FormItemType} from '../../../../../types';
import TextArea from '../TextArea';
import { uploadFile, getFileUrl } from '../../../../../api/FormFileService';
import { Button } from '@material-ui/core';

export default function Picture({item}: FormItemProps){
    const [image, setImage] = useState<string>("");
    const [showChange, setShowChange] = useState<boolean>();

    useEffect(() => {
        if(item.answers !== undefined && item.answers !== null && item.answers.length > 0)
            setImage(getFileUrl(item.answers[0]));
    }, [item])

    function onImageChange(event: React.ChangeEvent<HTMLInputElement>){
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];

            uploadFile(img)
                .then((data : any) => {
                    let newAnswers = [data.filename];
                    item.answers = newAnswers;

                    toBase64(img)
                        .then((data) => {
                            setImage(data as string);
                        });
                }) // TODO toast
                .catch((error) => alert("Error"));
        }
    }

    function deleteImage(){
        item.answers = [];
        setImage("");
    }

    const toBase64 = (file : File) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    return(
        <FormControl component="fieldset">
            <FormLabel component="legend">{item.title}</FormLabel>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {
                            (item.answers === undefined || item.answers === null || item.answers.length < 1 || showChange) ?
                            <>
                                <input type="file" name="myImage" onChange={onImageChange} />
                                {
                                    item.answers !== undefined && item.answers !== null && item.answers.length > 0 &&
                                        <Button size="small" variant="outlined" onClick={() => deleteImage()}>
                                            Verwijderen
                                        </Button>
                                }
                            </>
                               
                            :
                                <>
                                    <Button size="small" variant="outlined" onClick={() => setShowChange(true)}>
                                        Wijzigen
                                    </Button>
                                    <br />
                                    <Button size="small" variant="outlined" onClick={() => deleteImage()}>
                                        Verwijderen
                                    </Button>
                                </>
                        }
                        
                    </Grid>
                    <Grid item xs={4}>
                        <img src={image} className={styles.imagePreview} />
                    </Grid>
                </Grid>
            </Box>
            {
                item.children.length > 0 && item.children[0].type === FormItemType.TextArea &&
                    <div className={styles.comments}>
                        <TextArea item={item.children[0]} />
                    </div>
            }
        </FormControl>
    )
}