class User {
    name: string | null
    email: string | null
    loggedIn: boolean
    role: string | null

    constructor() {
        this.name = localStorage.getItem('userName')
        this.email = localStorage.getItem('userEmail')
        this.role = localStorage.getItem('userRole')
        this.loggedIn = localStorage.getItem('userLoggedIn') === 'true' ? true : false;
    }

    init(){
        this.name = localStorage.getItem('userName')
        this.email = localStorage.getItem('userEmail')
        this.role = localStorage.getItem('userRole')
        this.loggedIn = localStorage.getItem('userLoggedIn') === 'true' ? true : false;
    }

    /**
     *
     * @param data object
     * @param data.name string
     * @param data.email string
     * @param callback function
     */
    authenticated(data : any) {
        localStorage.setItem('userName', data.name)
        localStorage.setItem('userEmail', data.email)
        localStorage.setItem('userRole', data.role);
        localStorage.setItem('userLoggedIn', 'true')

        this.init()
    }

    unauthenticated(){
        localStorage.removeItem('userName');
        localStorage.removeItem('userEmail');
        localStorage.removeItem('userRole');
        localStorage.setItem('userLoggedIn', 'false');

        this.init()
    }

    /**
     *
     * @return {boolean}
     */
    isLoggedIn(): boolean {
        return Boolean(this.loggedIn) === true
    }
}

export default new User()