import React, { useEffect, useState } from 'react';
import { deleteForm } from '../api/FormService';
import { Form, FormStatus } from '../types';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Loader from '../../Loader';
import {Link} from 'react-router-dom';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { User } from '../../Auth';
import { Checkbox, FormControlLabel } from '@mui/material';
  
  const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));

interface OverviewProps{
    forms: Form[]
    toggleFormIdToDownload: (formId: number) => void | undefined;
    formIdsToDowload: number[]
}

export default function Overview(props: OverviewProps){
    const [forms, setForms] = useState(props.forms);

    const handleDeleteForm = (id: number) => {
        if(window.confirm(`Weet u zeker dat u formulier ${id} wilt verwijderen?`)){
            deleteForm(id)
                .then(() => {
                    alert("Het formulier is succesvol verwijderd.")

                    // Delete form from display
                    let newArray = [...forms];
                    let index = newArray.findIndex(form => form.id === id);
                    newArray.splice(index, 1);
                    setForms(newArray);
                    // TODO
                })
                .catch(() => alert("Er is een fout opgetreden tijdens het verwijderen van het formulier."))
        }
    }

    const showStatus = (status: FormStatus | undefined) => {
        switch(status){
            case FormStatus.created:
                return <Chip label="todo" color="secondary" />;
            case FormStatus.filled:
                return <Chip label="ingevuld" color="primary" variant="outlined" />;
            case FormStatus.ready:
                return <Chip label="afgerond" color="success" variant="outlined" />;
            case FormStatus.downloaded:
                return <Chip label="gedownload" color="success" />;
            default:
                return <Chip label="onbekend" color="error" variant="outlined" />
        }
    }

    const handleChangeDownloadCheckbox = (formId: number) => {
        if(props.toggleFormIdToDownload){
            props.toggleFormIdToDownload(formId);
        }
    }

    return(
        <>
        {
                <Demo>
                    <List>
                        {
                            forms.map(function(form){
                                return(
                                   
                                        <ListItem
                                            key={form.id}
                                            secondaryAction={
                                                <>
                                                    {showStatus(form.status)}
                                                    {
                                                        (User.role === '1' && 
                                                            (form.status === FormStatus.filled || form.status === FormStatus.ready || form.status === FormStatus.downloaded)) &&
                                                            <>
                                                                 <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteForm(form.id as number)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                                <a href={"/api/pdf/" + form.id} target="_blank">
                                                                    <IconButton edge="end" aria-label="download">
                                                                        <PictureAsPdfIcon />
                                                                    </IconButton>
                                                                </a>
                                                                <Checkbox
                                                                    checked={props.formIdsToDowload.find(x => x === form.id as number) !== undefined}
                                                                    onChange={() => handleChangeDownloadCheckbox(form.id as number)}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                />
                                                            </>
                                                    }
                                                </>
                                            }
                                            >
                                                <Link to={'/forms/' + form.id} style={{display: "contents"}}>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                        <AssignmentIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={form.title}
                                                        secondary={`${form.createdAt}`}
                                                    />
                                                </Link>
                                        </ListItem>
                                )
                            })
                        }                    
                    </List>
                </Demo>
        }
        </>
    );
}