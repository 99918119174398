import React from 'react';
import { FormItemType } from '../../../types';
import Category from './parts/Category';
import RadioButtons from './parts/RadioButtons';
import Picture from './parts/Picture';
import TextArea from './parts/TextArea';
import RepeatingChildren from './parts/RepeatingChildren';
import { FormItemProps } from './types';
import styles from './index.module.css';
import SingleCheckbox from './parts/SingleCheckbox';
import MultiCheckboxes from './parts/MultiCheckboxes';
import Draw from './parts/Draw';
import {FormItem as FormItemObject} from '../../../types'
import TextFieldC from './parts/TextField';

export default function FormItem({key, item}: FormItemProps){   
    function renderFormItem(item: FormItemObject){
        switch(item.type){
            case FormItemType.Category:
                return <Category item={item} key={key} />;
            case FormItemType.RepeatingChildren:
                return <RepeatingChildren item={item} />;
            case FormItemType.TextArea:
                return <TextArea item={item} />;
            case FormItemType.Picture:
                return <Picture item={item} />;
            case FormItemType.SingleCheckbox:
                return <SingleCheckbox item={item} />;
            case FormItemType.MultiCheckboxes:
                return <MultiCheckboxes item={item} />;
            case FormItemType.MultiRadio:
                return <RadioButtons item={item} />;
            case FormItemType.Draw:
                return <Draw item={item} />;
            case FormItemType.TextField:
                return <TextFieldC item={item} />;
        }
    } 

    return(
        item.type === FormItemType.Category ? 
            renderFormItem(item)
            :
            <div className={styles.margin} key={key}>
                {renderFormItem(item)}
            </div>
    )
}