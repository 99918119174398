import React, { useState } from 'react';
import { CreateForm, EditForm, FormOverview } from '../Form';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import { NavLink, Route, Routes } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { User } from '../Auth';
import Logout from '../Auth/Logout';
import FolderIcon from '@mui/icons-material/Folder';
import { CreateFormGroup, EditFormGroup, FormGroupOverview, ViewFormGroup } from '../FormGroup';

interface LayoutProps{
  authenticatedEvent?: (isAuthenticated: boolean) => void
}

export default function Layout({authenticatedEvent} : LayoutProps){
  const [showDrawer, setShowDrawer] = useState(false);

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  }

  const refreshPage = () => {
    window.location.reload();
  }

  const routes = [
      { path: '/', element: <FormGroupOverview /> },
      { path: '/formGroups', element: <FormGroupOverview /> },
      { path: '/formGroups/:id', element: <ViewFormGroup /> },
      { path: '/formGroups/:id/edit', element: <EditFormGroup /> },
      { path: '/formGroups/:formGroupId/newForm', element: <CreateForm /> },
      { path: '/formGroups/create', element: <CreateFormGroup /> },
      { path: '/forms/:id', element: <EditForm />},
      { path: '/signout', element: <Logout authenticatedEvent={authenticatedEvent} />}
  ];
  
  return (
    <>
      <AppBar position="fixed">
          <Toolbar variant="dense">
           <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
               <MenuIcon onClick={toggleDrawer} />
            </IconButton>
            <Typography variant="h6" color="inherit" component="div">
              Forms
            </Typography>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <Container maxWidth="md">
          <Routes>
            {routes.map(r => <Route key={r.path} {...r} />)}
          </Routes>
        </Container>
        <SwipeableDrawer
            anchor="left"
            open={showDrawer}
            onClose={toggleDrawer}
            onOpen={toggleDrawer}
          >
             <Box
              sx={{ width: 250 }}
              role="presentation"
              onClick={toggleDrawer}
              onKeyDown={toggleDrawer}
            >
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <AccountCircleIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={User.name} />
                </ListItem>
              </List>
              <Divider />
              <List>
                  <NavLink to="/">
                    <ListItem button key={1}>
                      <ListItemIcon>
                        <FolderIcon />
                      </ListItemIcon>
                      <ListItemText primary="Dossiers" />
                    </ListItem>
                  </NavLink>
              </List>
              <Divider />
              <ListItem button key={1} onClick={refreshPage}>
                      <ListItemIcon>
                        <FolderIcon />
                      </ListItemIcon>
                      <ListItemText primary="Update" />
                    </ListItem>
                    <Divider />
              <List>
                  <NavLink to="/signout">
                    <ListItem button key={1}>
                      <ListItemIcon>
                        <LogoutIcon />
                      </ListItemIcon>
                      <ListItemText primary="Uitloggen" />
                    </ListItem>
                  </NavLink>
              </List>
              <Divider />
              <br /><br />
              Copyright Rietberg Web
            </Box>
        </SwipeableDrawer>
  </>
  );
}