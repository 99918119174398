import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormItemProps } from '../../types';

export default function SingleCheckbox({item}: FormItemProps){
    function handleChange(event: React.ChangeEvent<HTMLInputElement>){
        item.answers = [event.target.checked ? "true" : "false"];
    }

    return(
        <FormControlLabel control={<Checkbox onChange={handleChange} />} label={item.title} />
    )
}