import React, {useEffect, useState, FC} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Loader from '../../Loader';
import { FormGroupDetail, FormGroup } from '../types';
import { Alert } from '@mui/material';
import { getFormGroup, updateFormGroup } from '../api/FormGroupService';

const EditFormGroup: FC = () => {
    const { id } = useParams();
    const [formGroup, setFormGroup] = useState<FormGroup | undefined>();
    const [createdId, setCreatedId] = useState<number | undefined>(undefined);
    const [redirectBack, setRedirectBack] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [values, setValues] = useState<Map<number, string>>(new Map<number, string>());
    const [hasErrors, setHasErrors] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if(id !== undefined){
            let idN : number = +id;
            setIsLoading(true);

            getFormGroup(idN)
                .then(function(data: FormGroup) {
                    setFormGroup(data);

                    if(data.details){
                        const newValues = new Map<number, string>();

                        data.details.forEach((detail) => {
                            newValues.set(detail.id as number, detail.content);
                        });

                        setValues(newValues);
                        console.log(newValues);
                    }

                    setIsLoading(false);
                });
            }
    }, [])

    function onChange(event: React.ChangeEvent<HTMLInputElement>){
        const id : number = +event.target.name;
        const newValues = new Map<number, string>(values);
        newValues.set(id, event.target.value);
        console.log("onChange");
        console.log(values);
        setValues(newValues);
    }

    function validate(){
        if(formGroup && formGroup.details){
            if(values.size !== formGroup.details.length){
                return false;
            }

            values.forEach((value) => {
                if(value === ''){
                    return false;
                }
            });

            return true;
        }

        return false;
    }

    function onSubmit(){
        const isValid = validate();
        setHasErrors(!isValid);

        if (isValid){
            const details : FormGroupDetail[] = [];

            values.forEach((value, lId) => {
                if(lId)
                    details.push({id: +lId, content: value});
            })

            updateFormGroup({
                id: id ? +id : undefined,
                details: details,
            })
                .then((data) => {
                    setCreatedId(data.id);
                });
        }
    }

    if (createdId !== undefined){
        navigate("/formGroups/" + createdId);
    }

    return(
        isLoading ?
            <Loader />
        :
        <>
            {
                createdId !== undefined ?
                    <Navigate to={"/"} />
                :
                    <>
                        <AppBar position="fixed">
                            <Toolbar variant="dense">
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2 }}
                                >   
                                    <CloseIcon onClick={() => navigate("/formGroups/" + formGroup?.id)} />           
                                </IconButton>
                                <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }}>
                                    Bewerk dossier
                                </Typography>
                                </Toolbar>    
                        </AppBar>
                        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                            Bewerk dossier
                        </Typography>

                        {
                            hasErrors && <Alert severity="error">Vul alle velden in om een dossier aan te maken.</Alert>
                        }
                        
                        {
                            formGroup && formGroup.details && (
                                <>
                                    {
                                        formGroup.details.map(element => {
                                            const id = element.id as number;

                                            return(
                                                <div key={element.id}>
                                                {console.log(element)}
                                                    <TextField
                                                        id="fullWidth outlined-static"  
                                                        required 
                                                        fullWidth 
                                                        name={id.toString()}
                                                        label={element.field?.title} 
                                                        rows={4} 
                                                        onChange={onChange} value={values.has(id) ? values.get(id) : values.get(id)} />
                                                    <br />
                                                    <br />
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            )
                        }
                        <Button variant="contained" onClick={onSubmit}>Dossier aanmaken</Button>
                    </>
            }
        </>
    )
}

export default EditFormGroup;