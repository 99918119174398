import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import {FormItemProps} from '../../types';

export default function TextFieldC({item}: FormItemProps){
    const [value, setValue] = useState(item.answers !== null && item.answers.length > 0 ? item.answers[0] : "");

    function handleChange(e: React.ChangeEvent<HTMLInputElement>){
        setValue(e.target.value);
        let newAnswers = [e.target.value];
        item.answers = newAnswers;
    }

    return(
        <TextField
          id="fullWidth outlined-static"
          label={item.title}
          value={value}
          fullWidth
          rows={4}
          onChange={handleChange}
        />
    )
}