import React, { FC, useState, useEffect } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../Loader';
import { getFormGroup } from '../api/FormGroupService';
import { FormGroup } from '../types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { FormGroupOverview } from '..';
import { FormOverview } from '../../Form';
import { User } from '../../Auth';

const View: FC = () => {
    const { id } = useParams();
    const [formGroup, setFormGroup] = useState<FormGroup | undefined>();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [formIdsToDownload, setFormIdsToDownload] = useState<number[]>([]);

    useEffect(() => {
        setIsLoading(true);

        if(id){
            getFormGroup(+id)
                .then(data => {
                    setFormGroup(data);
                    setIsLoading(false);
                })
                .catch(() => setIsLoading(false));
        }
    }, []);

    const toggleFormIdToDownload = (formId: number) => {
        let newFormIdsForDownload = [...formIdsToDownload];
        const i = newFormIdsForDownload.findIndex(x => x === formId);

        console.log("index", i);
        console.log(newFormIdsForDownload);

        if(i === -1){
            // Add
            newFormIdsForDownload.push(formId);
        } else {
            // Delete
            newFormIdsForDownload.splice(i, 1); 
        }

        setFormIdsToDownload(newFormIdsForDownload);
    }

    const getDownloadUrl = (formIds: number[]) => {
        return `/api/pdf/group/${formIds.toString()}`;
    }

    if (isLoading) {
        return <Loader />;
    }
    else if (formGroup){
        return(
            <>
                <AppBar position="fixed">
                    <Toolbar variant="dense">
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >   
                            <CloseIcon onClick={() => navigate("/formGroups")} />           
                        </IconButton>
                        <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }}>
                            Dossier: {formGroup.title}
                        </Typography>
                        </Toolbar>    
                </AppBar>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                    {formGroup.title}
                </Typography>
                <Link to="newForm">
                    <Button variant="contained">Nieuw formulier</Button>
                </Link>
                &nbsp;
                <Link to="edit">
                    <Button variant="contained">Bewerken</Button>
                </Link>
                {User.role === '1' &&
                    <>
                        &nbsp;
                        {
                            formIdsToDownload.length === 0 ?
                                <Button variant="contained" disabled>Download</Button>
                                :
                                <a href={getDownloadUrl(formIdsToDownload)} target="_blank">
                                    <Button variant="contained">Download</Button>
                                </a>
                        }
                    </>
                }
                {formGroup.forms && 
                    <FormOverview 
                        forms={formGroup.forms} 
                        formIdsToDowload={formIdsToDownload}
                        toggleFormIdToDownload={toggleFormIdToDownload}
                    />
                }
            </>
        )
    } else{
        return(
            <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                Dossier niet gevonden
            </Typography>
        )
    }
};

export default View;
